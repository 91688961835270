import { createLocalizedPathnamesNavigation } from 'next-intl/navigation'

import { LOCALES } from '@ralph/db/utils/config'

export const { Link, redirect, usePathname, useRouter } =
  createLocalizedPathnamesNavigation({
    locales: LOCALES,
    pathnames: {
      '/': '/',
    },
  })
