'use client'

import React from 'react'
import { redirect, useSelectedLayoutSegments } from 'next/navigation'
import { usePathname } from '@/lib/navigation'
import { BaselimeErrorBoundary, useBaselimeRum } from '@baselime/react-rum'
// import { H } from '@highlight-run/next/client'
import { sendGTMEvent } from '@next/third-parties/google'

import { createSupabaseBrowserClient } from '@ralph/db/supabase/supaClients'
// import { toast } from 'sonner'

import { LOCALE } from '@ralph/db/utils/config'
import { normalizeData, normalizeEmailData } from '@ralph/db/utils/mappers'

import { ContextMemoWrapper } from './ContextMemoWrapper'

export interface AnalyticsContextProps {
  trackProperty: (slug?: string) => void
  changeLocale: (newLocale: LOCALE) => void
}

interface UserTrackingData {
  userId: string
  userEmail: string
  userPhone: string
  userHash: string
  propertySlug: string
}

const initialContextState: AnalyticsContextProps = {
  trackProperty: () => {},
  changeLocale: () => {},
}

export const AnalyticsContext =
  React.createContext<AnalyticsContextProps>(initialContextState)

interface AnalyticsContextProviderProps {
  children: React.ReactNode
  locale: LOCALE
}

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const AnalyticsContextProvider = ({
  children,
  locale,
}: AnalyticsContextProviderProps) => {
  const pathname = usePathname()
  const segment = useSelectedLayoutSegments()
  const { sendEvent, setUser } = useBaselimeRum()

  const trackProperty = React.useCallback((slug?: string) => {
    window.dataLayer = window.dataLayer || []
    sendGTMEvent({
      propertySlug: slug,
    })
  }, [])

  const changeLocale = React.useCallback((newLocale: LOCALE) => {
    window.dataLayer = window.dataLayer || []
    sendGTMEvent({
      event: 'LOCALE_CHANGED',
      language: newLocale,
    })
  }, [])

  React.useEffect(() => {
    const pathWithoutUUID = segment
      .filter((key) => !uuidRegex.test(key))
      .join('/')
    const uuids = segment.filter((key) => uuidRegex.test(key))
    const propertyId = uuids?.[0]
    const unitId = uuids?.[1]
    window.dataLayer = window.dataLayer || []
    sendGTMEvent({
      event: 'pageview',
      language: locale,
      page: pathWithoutUUID,
      propertyId: propertyId,
      unitId: unitId,
      pageFull: pathname,
    })
    // toast.dismiss()
  }, [pathname, locale, segment])

  React.useEffect(() => {
    const supabaseClient = createSupabaseBrowserClient()
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        window.dataLayer = window.dataLayer || []
        const email = normalizeEmailData(session.user.email)
        const firstName = normalizeData(session.user.user_metadata?.first_name)
        const lastName = normalizeData(session.user.user_metadata?.last_name)
        sendGTMEvent({
          event: 'signin',
          userId: session.user.id,
          userEmail: email,
          userFirstName: firstName,
          userLastName: lastName,
          userHash: session.user.user_metadata?.intercomHash,
          userPhone: session.user.phone,
        })
        try {
          if (email) {
            // H.identify(email, {
            //   highlightDisplayName: email,
            //   ...(firstName && { firstName }),
            //   ...(lastName && { lastName }),
            //   id: session.user.id,
            // })
          }
          setUser(session.user.id)
        } catch (e) {
          console.error(e)
        }
      } else {
        window.dataLayer = window.dataLayer || []
        sendGTMEvent({
          event: 'signout',
          userId: null,
          userEmail: null,
          userFirstName: null,
          userLastName: null,
          userHash: null,
          userPhone: null,
        })
        setUser(undefined)
      }
    })
    return () => subscription.unsubscribe()
  }, [])

  const values = React.useMemo(() => {
    return {
      trackProperty,
      changeLocale,
    }
  }, [trackProperty, changeLocale])

  return (
    <>
      <AnalyticsContext.Provider value={values}>
        <ContextMemoWrapper>{children}</ContextMemoWrapper>
      </AnalyticsContext.Provider>
    </>
  )
}
